<template>
   <div class="visains-container pb-3 pt-2">
      <!-- <navbar/> -->
      <div class="container mt-5">
         <div class="row d-flex justify-content-center">
            <div class="d-flex justify-content-center">
               <div class="price mb-3">VISA + INSURANCE : AED {{ visains.price.price }}</div>
            </div>
            <div class="col-4">
               <b-form @submit.prevent="submit" class="form mb-3">

                  <b-form-group id="type-label" label="Type of Visa:" label-for="typevisa">
                  <b-form-select
                     id="type-label"
                     v-model="visains.price"
                     :options="options"
                     class="w-50 select mb-3"
                     required
                  ></b-form-select>
                  </b-form-group>
                  <b-form-group
                  id="email-label"
                  label="Email address:"
                  label-for="email"
                  description="We'll never share your email with anyone else."
                  class="mb-3"
                  >
                  <b-form-input
                     id="email"
                     v-model="visains.email"
                     type="email"
                     placeholder="Enter your email address"
                     required
                  ></b-form-input>
                  </b-form-group>

                  <!-- ----------------------------------------------------------- -->

                  <b-form-group
                  id="name-label"
                  label="Name:"
                  label-for="name"
                  class="mb-3"
                  >
                  <b-form-input
                     id="name"
                     v-model="visains.name"
                     type="text"
                     placeholder="Enter Full Name"
                     required
                  ></b-form-input>
                  </b-form-group>

                  <b-form-group id="upload-label" label="Upload Passport:" label-for="pp-upload" class="mb-3 mt-3">
                     <b-form-file
                        v-model="visains.passport"
                        accept=".pdf"
                        class="mt-3"
                        :no-drop="true"
                        required
                        multiple
                     ></b-form-file>
                  </b-form-group>

                  <b-form-group id="upload-label" label="Upload Photo:" label-for="pp-upload" class="mb-3 mt-3">
                     <b-form-file
                        v-model="visains.photo"
                        accept="image/jpeg, image/png, image/jpg"
                        class="mt-3"
                        :no-drop="true"
                        required
                        multiple
                     ></b-form-file>
                  </b-form-group>

                  <!-- ----------------------------------------------------------- -->

                  <div class="row" style="padding: 0; margin: 0">
                     <div class="col-md-3" style="padding: 0; margin: 0 10px 0 0">
                        <b-form-group
                        id="name-label"
                        label="Code:"
                        label-for="code"
                        class="mb-3"
                        >
                        <b-form-input
                           id="code"
                           v-model="visains.code"
                           type="number"
                           placeholder="971"
                           required
                        ></b-form-input>
                        </b-form-group>
                     </div>   
                     <div class="col-md" style="padding: 0; margin: 0">
                        <b-form-group
                        id="name-label"
                        label="Mobile Number:"
                        label-for="number"
                        class="mb-3"
                        >
                        <b-form-input
                           id="number"
                           v-model="visains.number"
                           type="number"
                           placeholder="Enter Mobile Number"
                           required
                           
                        ></b-form-input>
                        </b-form-group>
                     </div>   
                  </div>                              

                  <b-form-group id="adult-label" label="No: of Adults" label-for="adults">
                  <b-form-select
                     id="adults"
                     v-model="visains.adults"
                     :options="optionsAdults"
                     class="w-50 select mb-2"
                     required
                  ></b-form-select>
                  </b-form-group>

                  <!-- ----------------------------------------------------------- -->

                  <b-form-group id="adult-label" label="No: of Children" label-for="children">
                  <b-form-select
                     id="children"
                     v-model="visains.children"
                     :options="optionschild"
                     class="w-50 select mb-2"
                     required
                  ></b-form-select>
                  </b-form-group>

                  <!-- ----------------------------------------------------------- -->

                  <b-form-group id="adult-label" label="No: of Infants" label-for="infant">
                  <b-form-select
                     id="infant"
                     v-model="visains.infants"
                     :options="optionsinfant"
                     class="w-50 select mb-2"
                     required
                  ></b-form-select>
                  </b-form-group>
                  
                  <hr/>

                  <div class="row">
                     <b-button class="submit" type="submit">Submit Request</b-button>
                     <div class="error mt-3" v-if="error.length != 0">{{ error }}</div>
                  </div>
               </b-form>

            </div>
         </div>
      </div>
   </div>
</template>
<script>
// import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
import _ from 'lodash'

export default {
//   components: { Navbar },
   data() {
      return {
         visains: {
            price: {
               price: 0
            }
         },
         phonenumber: Number,
         error: '',
         options: [
            {value: {price: 400, text: 'VISA SINGLE 30 DAYS'}, text: 'VISA SINGLE 30 DAYS'},
            {value: {price: 800, text: 'VISA SINGLE 90 DAYS'}, text: 'VISA SINGLE 90 DAYS'},
            {value: {price: 2000, text: 'VISA MULTIPLE 90 DAYS'}, text: 'VISA MULTIPLE 90 DAYS'}
         ]
      }
   },
   methods: {
      async submit() {
         let vm = this

         if(!_.isEmpty(this.visains.code) && !_.isEmpty(this.visains.number)) {
            this.phonenumber = this.visains.code + this.visains.number
         } else {
            return this.error = 'Phone Number is not valid'
         }

         let visatable = new FormData()

         visatable.append('name', JSON.stringify(vm.visains.name))
         visatable.append('adults', JSON.stringify(vm.visains.adults))
         visatable.append('children', JSON.stringify(vm.visains.children))
         visatable.append('infants', JSON.stringify(vm.visains.infants))
         visatable.append('email', JSON.stringify(vm.visains.email))
         visatable.append('number', JSON.stringify(vm.phonenumber))
         visatable.append('type', JSON.stringify(vm.visains.price.text))

         for(let i=0; i < _.size(vm.visains.passport); i++) {
            visatable.append('passport', vm.visains.passport[i])
         }

         for(let i=0; i < _.size(vm.visains.photo); i++) {
            visatable.append('photo', vm.visains.photo[i])
         }

         await axios.post('https://www.desertlinktours.com/api/visains', visatable, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {
            if(res.status === 200) {
               vm.error = "REQUEST SUBMITTED SUCCESSFULLY"
            } else {
               vm.error = "There was a processing error! Try Again Later!"
            }
            setTimeout(() => {
               vm.resetForm()
            }, 3000)

         }).catch((err) => console.debug(err))
      },
      resetForm() {
         this.visains = {
            name: '',
            adults: '',
            children: '',
            infants: '',
            email: '',
            name: '',
            number: '',
            price: {
               price: 0
            }
         }
         this.phonenumber = ''
         this.error = ''
      },
   },
   computed: {
      optionsAdults: () => {
         let count = []
         for(let i = 0; i <= 30; i++) {
            count.push(i)
         }
         return count
      },
      optionschild: () => {
         let count = []
         for(let i = 0; i <= 30; i++) {
            count.push(i)
         }
         return count
      },
      optionsinfant: () => {
         let count = []
         for(let i = 0; i <= 10; i++) {
            count.push(i)
         }
         return count
      },
   }
}
</script>
<style lang="scss">
.custom-file-label {
      display: none!important;
   }
</style>
<style lang="scss" scoped>
body {
  height: 100%;
  background: url('../../assets/backgroundColor.png') !important;
}

.visains-container 
{
   // height: 100vh;
   background: url('../../assets/backgroundColor.png') !important;

   .price {
      padding: 20px;
      // border: 1px solid #212121;
      background: #212121;
      color: #fff;
      border-radius:4px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
   }

   .select {
      height: 30px;
      border: 1px solid #dddddd;
      text-align: center;
      border-radius: 4px;
   }

   .submit {
      width: 100%;
      text-align: center;
      background: #fff;
      color: #212121;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: .1em;
      transition: all 500ms ease-in-out;
      border: 1px solid #212121;

      &:hover {
         background: #212121;
         color: #fff;
         border: 1px solid #fff;
      }
   }

   .form {
      padding: 30px;
      background: #fff;
      // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
   }
}

</style>